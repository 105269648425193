import "bootstrap";
import Swiper from "swiper/dist/js/swiper";
import PerfectScrollbar from "perfect-scrollbar";
import "jquery.scrollto";

$(window).scroll(function () {
    var $heightScrolled = $(window).scrollTop();
    var $defaultHeight = 300;

    if ( $heightScrolled < $defaultHeight )
    {
        $('#logo').removeClass("logo2")
        $('#logo').addClass("logo")
    }
    else {
        $('#logo').removeClass("logo")
        $('#logo').addClass("logo2")
    }

});

$(document).on('click', '.slide_nav_open', function(){
    document.getElementById("mySidenav").style.width = "100vw";
});

$(document).on('click', '.slide_nav_close', function(){
    document.getElementById("mySidenav").style.width = "0";
});

if($('.event_tag_open').hasClass('open')){
    document.getElementById("nav-btm").style.marginTop = "0";
}

$(document).on('click', '.event_tag_open', function(){
    document.getElementById("nav-btm").style.marginTop = "0";
});


// console.log(($('.nav-btm').outerHeight() * -1));
// document.getElementById("nav-btm").style.marginTop = ($('.nav-btm').outerHeight() * -1)+'px';
// document.getElementById("nav-btm").style.marginTop = '-260px';

$(document).ready(function(){
    document.getElementById("nav-btm").style.marginTop = ($('.nav-btm').outerHeight() * -1)+'px';
});
$(document).on('click', '.event_tag_close', function(){
    // document.getElementById("nav-btm").style.marginTop = "-200px";
    document.getElementById("nav-btm").style.marginTop = ($('.nav-btm').outerHeight() * -1)+'px';
});

$(document).on('change', '.specify', function(){
    console.log($(this));

    var value_for = $(this).attr('value_for');

    $('.' + value_for).val($(this).val());

    $('.' + value_for).attr('checked', true);
});

function add(ths,sno){


    for (var i=1;i<=5;i++){
        var cur=document.getElementById("star"+i)
        cur.className="fa fa-star"
    }

    for (var i=1;i<=sno;i++){
        var cur=document.getElementById("star"+i)
        if(cur.className=="fa fa-star")
        {
            cur.className="fa fa-star checked"
        }
    }

}

function childs(ths,sno){


    for (var i=1;i<=5;i++){
        var cur=document.getElementById("child"+i)
        cur.className="fa fa-star child"
    }

    for (var i=1;i<=sno;i++){
        var cur=document.getElementById("child"+i)
        if(cur.className=="fa fa-star child")
        {
            cur.className="fa fa-star child checked"
        }
    }

}

var swiper = new Swiper('.swiper-container', {
    slidesPerView: 5,
    spaceBetween: 30,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    autoplay: {
        delay: 3500,
    },
    breakpoints: {
        576: {
            slidesPerView: 1,
            spaceBetween: 10
        },
        769: {
            slidesPerView: 2,
            spaceBetween: 10
        }
    }
});

// const ps = new PerfectScrollbar('.month-right', {
//     wheelSpeed: 2,
//     wheelPropagation: false,
//     minScrollbarLength: 20
// });
